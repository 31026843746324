export enum TypeEvent {
    All = "ALL",
    Navigation = "NAVIGATION",
    Repos = "REPOS",
    Chantier = "CHANTIER",
    EntretienBateau = "ENTRETIEN BATEAU",
    ConvoyageBateau = "CONVOYAGE BATEAU",
    Formation = "FORMATION",
    DeplacementPro = "DEPLACEMENT PRO",
    Ecole = "ECOLE",
    Reunion = "REUNION",
    CaisseLaRade = "CAISSELARADE",
    CaisseToulon = "CAISSETOULON",
    CaisseLaLonde = "CAISSELALONDE",
    Direction = "DIRECTION",
    Maladie = "MALADIE",
    Conges = "CONGES",
    Escale = "ESCALE",
    Presta = "PRESTA",
    RS = "RESEAUX SOCIAUX",
    Affretement = "AFFRETEMENT",
    Bureau = "BUREAU",
    Recuperation = "RECUPERATION",
    ReposAnticipe = "REPOS ANTICIPE",
    Prospectus = "PROSPECTUS",
    FinContrat = "FINCONTRAT",
    Quai = "QUAI",
    Autre = "AUTRE",

}
